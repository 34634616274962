// @ts-check
import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

import { CardImageText } from "./cardImageText"

export const CardAutomation = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "tools/automation_that_works.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1000
            quality: 100
            traceSVG: { background: "transparent", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <CardImageText
      variant="lightgrey"
      isReverseRow
      title="Automation that really works"
      bodyText="Mavenoid diagnoses and solves tough problems that chatbots and help
          centers can’t handle. Guides, images and video make your instructions
          truly useful."
      img={
        <Img
          fluid={image.file.childImageSharp.fluid}
          objectFit="contain"
          alt="Picture of Mavenoid bot solving problems"
          style={{ width: "100%" }}
        />
      }
    />
  )
}

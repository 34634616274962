import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

import { CardImageText } from "./cardImageText"

export const CardAutoPilot = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "try-it-free/fts.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1000
            quality: 100
            traceSVG: { background: "transparent", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <CardImageText
      variant="lightgrey"
      title="Put any support flow on autopilot"
      bodyText="The product assistant helps with FAQs, guides, troubleshooting, spare
          parts and much more. Your self-service offering becomes better with
          every flow you automate."
      isFixedImageHeight
      imageHasPadding
      img={
        <Img
          fluid={image.file.childImageSharp.fluid}
          objectFit="contain"
          alt="Picture of Mavenoid bot solving problems"
          style={{ width: "100%" }}
        />
      }
    />
  )
}

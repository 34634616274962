import React from "react"
import styled from "@emotion/styled"

import colors from "../../../styles/colors"
import { Accordion } from "../../accordion/accordion"
import { AccordionBody } from "../../accordion/accordionBody"
import { AccordionHeading } from "../../accordion/accordionHeading"
import { AccordionItem } from "../../accordion/accordionItem"
import Typography from "../../typography"
import { ToolsCard } from "./ToolsCard"
import { DesktopMediaQuery, MobileMediaQuery } from "../../../styles/constants"
import TeachableAI from "../../illustrations/teachableAI"
import NLP from "../../illustrations/NLP"
import CustomerMetrics from "../../illustrations/customerMetrics"
import ProductInsights from "../../illustrations/productInsights"
import Bullet from "../../icons/bullet"
import { trackEvent } from "../../../utils/trackEvent"

const Title = styled(Typography)`
  ${MobileMediaQuery} {
    margin-top: 1.5rem;
    margin-bottom: 3.5rem;
  }
`

const RightCardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.white};
`
const LeftCardWrapper = styled.div`
  background-color: ${colors.lightgrey};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MobileMediaQuery} {
    display: none;
  }
`
const MobileImageWrapper = styled.div`
  ${DesktopMediaQuery} {
    display: none;
  }
  background-color: ${colors.lightgrey};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 1rem;
  height: 100%;
`

const Description = styled(AccordionBody)`
  max-width: 400px;
`

export const CardAiFeatures = () => {
  const [activeId, setActiveId] = React.useState(0)

  const content = [
    {
      icon: <Bullet />,
      title: "Natural language understanding",
      description: `Speak instead of type. Customers can use their own words and even
          their own language. No system training needed.`,
      image: <NLP />,
    },
    {
      icon: <Bullet />,
      title: `Teachable AI`,
      description: `The system learns from every interaction, and continues to improve on
          its own`,
      image: <TeachableAI />,
    },
    {
      icon: <Bullet />,
      title: `Customer metrics`,
      description: `Track resolution rate, customer satisfaction, and more to understand
          how you’re doing.`,
      image: <CustomerMetrics />,
      imageMobile: <CustomerMetrics maskId="mask2" />,
    },
    {
      icon: <Bullet />,
      title: `Deep product insights`,
      description: `Uncover the root causes of your support requests and feed the
          information back into product development.`,
      image: <ProductInsights />,
      imageMobile: <ProductInsights maskId="mask02" />,
    },
  ]
  React.useEffect(() => {
    trackEvent({
      category: "view_item",
      label: "self service ai feature view",
      siteSection: "self service ai feature card",
      featureListIndex: activeId,
    })
  }, [activeId])
  return (
    <ToolsCard variant="white">
      <LeftCardWrapper>
        {content[activeId].image || `[IMAGE] - ${activeId}`}
      </LeftCardWrapper>
      <RightCardWrapper hasPadding>
        <Title variant="h3">AI-driven features, built for humans</Title>
        <Accordion preExpanded={0} onChange={setActiveId}>
          {content.map((data, i) => (
            <React.Fragment key={i}>
              {i !== 0 && (
                <hr style={{ border: `1px solid ${colors.lightgrey}` }} />
              )}
              <AccordionItem uuid={i} key={data.title}>
                <AccordionHeading icon={data.icon}>
                  {data.title}
                </AccordionHeading>
                <Description style={{ color: colors.darkgrey }}>
                  <Typography variant="bodySmall">
                    {data.description}
                  </Typography>
                  <MobileImageWrapper>
                    {content[activeId].imageMobile ||
                      content[activeId].image ||
                      `[IMAGE] - ${activeId}`}
                  </MobileImageWrapper>
                </Description>
              </AccordionItem>
            </React.Fragment>
          ))}
        </Accordion>
      </RightCardWrapper>
    </ToolsCard>
  )
}

import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import { CardHeader } from "../../components/cards/tools/cardHeader"
import { CardRepetitiveTS } from "../../components/cards/tools/cardRepetitiveTS"
import { CardAutoPilot } from "../../components/cards/tools/cardAutoPilot"
import { CardAutomation } from "../../components/cards/tools/cardAutomation"
import { CardEasySetup } from "../../components/cards/tools/cardEasySetup"
import { CardAiFeatures } from "../../components/cards/tools/cardAiFeatures"
import { CardPlugAndPlay } from "../../components/cards/tools/cardPlugAndPlay"
import { CardLiveSupport } from "../../components/cards/tools/cardLiveSupport"
import GirishAgarwal from "../../components/cards/testimonials/GirishAgarwal"
import { CardFullWidth } from "../../components/cards/cardFullWidth"
import { trackEvent } from "../../utils/trackEvent"
import SEO from "../../components/seo"

const SelfService = () => {
  const images = useStaticQuery(graphql`
    query {
      headerDesktop: file(
        relativePath: { eq: "tools/self-service-chainsaw.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 1500
            quality: 80
            traceSVG: {
              color: "#5C477E"
              background: "#333"
              blackOnWhite: false
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout
      bg="white"
      newsletter={null}
      headerColor="lightpurple"
      activeTab="product"
    >
      <SEO title="Self service" />
      <CardHeader
        fluid={images.headerDesktop.childImageSharp.fluid}
        variant="lightpurple"
        titleTextColor="lightpurple"
        textColor="white"
        breadcrumb="Self-service"
        title="Let customers help themselves"
        description="Customers love great self-service. With Mavenoid, you can automate
            any support flow for any product, from simple FAQs to advanced
            troubleshooting."
      />
      <CardRepetitiveTS />
      <CardAutoPilot />
      <CardAutomation />
      <CardEasySetup />
      <CardAiFeatures />
      <CardPlugAndPlay />
      <GirishAgarwal
        trackCustomerStory={() => {
          trackEvent({
            category: "view_item",
            label: "self service husqvarna customer story",
            siteSection: "self_service_testimonial",
          })
        }}
      />
      <CardFullWidth
        variant="lightpurple"
        title="Experience support that really works"
        description={
          <div style={{ maxWidth: "440px" }}>
            Unlike generic support tools that focus on deflection, Mavenoid is
            all about solutions.
            <br />
            <br /> Schedule a demo and experience how Mavenoid meets customers
            with the right level of support to take them seamlessly from request
            to solution.
          </div>
        }
        trackTryNow={() => {
          trackEvent({
            category: "sign_up_redirect",
            label: "self service try free try now",
            siteSection: "self_service_try_now_banner",
          })
        }}
      />
      <CardLiveSupport />
    </Layout>
  )
}

export default SelfService

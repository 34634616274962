import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import colors from "../../../styles/colors"
import Card from "../../card"
import { Testimonial } from "../../testimonial"
import { Husqvarna } from "../cardPartners"

const GirishAgarwal = ({ variant = "white", trackCustomerStory }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "customers/girish_agarwal.png" }) {
        childImageSharp {
          fixed(height: 210) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Card variant={variant} noMinHeight>
      <Testimonial
        name="Girish Agarwal"
        workTitle="Director AI Lab"
        company="Husqvarna Group"
        profilePictureData={data}
        logo={<Husqvarna fill={colors.darkgrey} style={{ height: "25px" }} />}
        linkTo="/customers/husqvarna/"
        onClick={trackCustomerStory}
        description="Mavenoid gives us the ability to scale automated support to all our markets."
      />
    </Card>
  )
}

export default GirishAgarwal

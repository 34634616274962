import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

import { CardImageText } from "./cardImageText"

export const CardEasySetup = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "try-it-free/stylized-ui.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 1240
            quality: 80
            traceSVG: { background: "transparent", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <CardImageText
      variant="lightgrey"
      leftPadding
      title="Easy setup, fast results"
      bodyText="We’ve made it easy to organize know-how scattered across manuals, tickets, websites, FAQs, and the minds of your workforce. Get Mavenoid on your website within a matter of minutes with ready-made templates."
      img={
        <Img
          fluid={image.childImageSharp.fluid}
          objectFit="contain"
          alt="A stylized version of the Mavenoid user interface"
          style={{ width: "100%" }}
        />
      }
    />
  )
}

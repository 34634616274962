import React from "react"
import styled from "@emotion/styled"

import colors from "../../../styles/colors"
import { CardContentWrapper } from "../../card"
import Typography from "../../typography"
import { ToolsCard } from "./ToolsCard"
import { DesktopMediaQuery, MobileMediaQuery } from "../../../styles/constants"
import SelfServiceDiagram from "../../illustrations/SelfServiceDiagram"

const RightCardWrapper = styled.div`
  background-color: ${colors.lightgrey};
  margin-left: auto;
  margin-right: 80px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 600px;
  width: 100%;
  padding: 2rem 5rem;

  ${MobileMediaQuery} {
    padding: 2rem 1.25rem;
    height: initial;
    align-items: center;
  }

  ${DesktopMediaQuery} {
    width: 414px;
    padding: 1.8rem;
  }
`
const LeftCardWrapper = styled(CardContentWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 700px;
  margin: 0;

  ${MobileMediaQuery} {
    padding-bottom: 2rem;
  }
`

const DiagramText = styled(Typography)`
  font-size: 12px;
`

const SelfServiceDiagramSVG = styled(SelfServiceDiagram)`
  ${MobileMediaQuery} {
    margin: 4rem 0;
  }
`

const DiagramTitle = styled(Typography)`
  max-width: 300px;
  ${MobileMediaQuery} {
    max-width: 250px;
  }
`

export const CardRepetitiveTS = () => {
  return (
    <ToolsCard variant="white" ratio={[2, 1]}>
      <LeftCardWrapper noMinHeight>
        <Typography variant="h3">
          Self-service used to be limited to basic tasks. Mavenoid lets you
          automate the single biggest time thief for support teams: repetitive
          troubleshooting. It’s where automation really pays off.
        </Typography>
      </LeftCardWrapper>
      <RightCardWrapper>
        <DiagramTitle variant="h6">
          Where support teams spend their time
        </DiagramTitle>
        <SelfServiceDiagramSVG />
        <DiagramText variant="overline">
          Based on Mavenoid's analysis of 8,000+ support tickets in 12 different
          product categories
        </DiagramText>
      </RightCardWrapper>
    </ToolsCard>
  )
}
